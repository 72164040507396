import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const TheLoft = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/the-loft/loft-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/the-loft/loft-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/the-loft/loft-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "The Loft Central Gurney",
        "The Loft Central Gurney",
        "The Loft Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | The Loft" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">The Loft</h2>
                        <p className="font-light text-gray-600">11am - 11pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 012-630 7470</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">      
                            The famed Loft Pizzeria & Bar is now coming to Central@Gurney, not only a must-try dining destination for any pizza lover but also boasts the title of Penang’s Best Wood-Fire Pizza. The chic interior setting of the restaurant is both classy and comfortable, making it the perfect spot for a romantic date night or a fun night out with friends. But it's not just the food and ambiance that sets The Loft apart, as customers rave about the friendly staff and good environment. The menu at the Loft offers a variety of pizza options, from classic Margherita to unique flavour combinations like Bruschetta. But that's not all, the menu also includes a variety of appetizers, salads, pastas and mains, as well as oysters and desserts to satisfy all tastes. The bar at The Loft serves up a great selection of beer and cocktails, making it the perfect spot to relax and enjoy a delicious meal with friends or family.
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-2-theloft.png`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default TheLoft
